import * as React from 'react';
import { Layout, Spin } from 'antd';

import './style.scss';

const Loader = (props) => {
    return (
        <Layout className="bg-dotted loader">
            <Spin />
        </Layout>
    );
};

export default Loader;
