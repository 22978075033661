import * as React from 'react';
import { createRoot } from 'react-dom/client';

import { QueryClient, QueryClientProvider } from 'react-query';
import * as Sentry from '@sentry/react';

import Loader from './modules/core/components/Loader';

const App = React.lazy(() => import('./App'));

if (process.env.RUN_ENV != 'development') {
    Sentry.init({
        dsn: 'https://e72c641211cd2cdf121f86117d9d6879@sentry.workist.com/8',
        environment: process.env.RUN_ENV,
    });
}

const container = document.getElementById('root');
const root = createRoot(container);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

root.render(
    <QueryClientProvider client={queryClient}>
        <React.Suspense fallback={<Loader />}>
            <App />
        </React.Suspense>
    </QueryClientProvider>
);
